<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-n2">
        <h4 style="font-weight: 500">Informations Bancaires </h4>
        </div>
        <hr  />
        <mdb-card class="z-depth-1">
            <mdb-card-body class="card-reversed">
                <form  @submit.prevent="updateBank()" >
                    <mdb-row>
                        <mdb-col sm="12" md="12">
                            <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed"
                             :disabled="!$gate.hasPermission(['edit::setting'])" 
                            :validation="$store.state.setting.errors.bank ? true :false" :invalidFeedback="$store.state.setting.errors.bank"
                            v-model="form.bank"
                             outline label="Banque" size="lg" ></mdb-input>
                        </mdb-col>
                        <mdb-col sm="12" md="6">
                            <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed"
                             :disabled="!$gate.hasPermission(['edit::setting'])" 
                            :validation="$store.state.setting.errors.iban ? true :false" :invalidFeedback="$store.state.setting.errors.iban"
                            v-model="form.iban"
                             outline label="IBAN" size="md" ></mdb-input>
                        </mdb-col>
                        <mdb-col sm="12" md="6">
                            <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed"
                             :disabled="!$gate.hasPermission(['edit::setting'])" 
                            :validation="$store.state.setting.errors.swift ? true :false" :invalidFeedback="$store.state.setting.errors.swift"
                            v-model="form.swift"
                             outline label="Swift" size="md" ></mdb-input>
                        </mdb-col>
                        <mdb-col sm="12" md="12">
                            <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed"
                             :disabled="!$gate.hasPermission(['edit::setting'])" 
                            :validation="$store.state.setting.errors.bank_address ? true :false" :invalidFeedback="$store.state.setting.errors.bank_address"
                            v-model="form.bank_address"
                             outline label="Adresse physique Banque" size="md" ></mdb-input>
                        </mdb-col>
                        <mdb-col sm="12" class="color-normal-reversed">
                            <label for="">Afficher les informations bancaires sur la facture</label>
                            <mdb-switch  :disabled="!$gate.hasPermission(['edit::setting'])" v-model="form.bank_is_active" offLabel="Non" onLabel="Oui"  />
                        </mdb-col>
                        <mdb-col sm="12" class="d-flex justify-content-end">
                           <button v-if="$gate.hasPermission(['edit::setting'])" type="submit" :disabled="submitBtn" class="btn btn-primary btn-md" > 
                                <span v-if="!submitBtn">Sauvegarder</span>
                                <span v-if="submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span v-if="submitBtn" class="pl-2">Loading...</span>
                             </button>
                        </mdb-col>
                    </mdb-row>
                </form>
            </mdb-card-body>
        </mdb-card>
    </div>
</template>

<script>
import {
    mdbInput,
    mdbRow,mdbCol,
    mdbCard,mdbCardBody,
    mdbSwitch,
} from 'mdbvue';
export default {
    metaInfo: {
      title: 'TECBILL APP',
      titleTemplate: '%s - PARAMETRE BANQUE',
      htmlAttrs: {
        lang: 'fr',
        amp: true
      }
    },
    components:{
        mdbInput,
        mdbRow,mdbCol,
        mdbCard,mdbCardBody,
        mdbSwitch,
    },
    data(){
        return{
            submitBtn:false,
            form:{
                id:this.$store.state.auth.company.setting.id,
                bank:this.$store.state.auth.company.setting.bank,
                iban:this.$store.state.auth.company.setting.iban,
                swift:this.$store.state.auth.company.setting.swift,
                bank_address:this.$store.state.auth.company.setting.bank_address,
                bank_is_active:this.$store.state.auth.company.setting.bank_is_active === 1 ? true : false
            }
        }
    },

    methods:{
        async updateBank() {
            this.$nprogress.start()
            this.$store.commit('setting/SET_CLEAN')
            this.submitBtn = !this.submitBtn
			await this.$store.dispatch('setting/updateBank', this.form)
			.then((response) => {
                this.$nprogress.done()
                this.submitBtn = !this.submitBtn
                this.$notify({
                     
                    message: response.data.message,
                    type: 'success'
                })
                this.$store.dispatch('auth/attempt',localStorage.getItem('token'));
			})
			.catch((error) => {
                this.$nprogress.done()
                this.submitBtn = !this.submitBtn
                if (error.response.data.errors) {
                    this.$store.commit('setting/SET_BANK', error.response.data.errors.bank)
                    this.$store.commit('setting/SET_IBAN', error.response.data.errors.iban)
                    this.$store.commit('setting/SET_SWIFT', error.response.data.errors.swift)
                    this.$store.commit('setting/SET_ADDR', error.response.data.errors.bank_address) 
                }
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
			})  
        },
    },
}
</script>